import styled, { css } from "styled-components";

export const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  flex-direction: column;
  padding-bottom: 60px;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      padding: 0 0 50px 0;
    `};
`;

export const PullToRefreshWrap = styled.div`
  position: relative;
`;

export const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: url("assets/icons/loading.svg") no-repeat center center;
`;

export const ListItem = styled.div`
  background: #fde4dd;
  border-radius: 4px;
  color: black;
  display: flex;
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  flex-direction: column;
  border-color: transparent;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      border-radius: 0;
      margin-bottom: 0;
      border-width: 0 0 1px 0;
    `};

  span {
    display: flex;
    min-width: 50px;
    height: 50px;
    margin: 10px;
    align-items: center;
    font-size: 30px;
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    @media (max-width: 1024px) {
      font-size: 24px;
    }
  }

  ${({ type }) =>
    type === "1" &&
    css`
      background: #fdf7dd;
      border-color: #f9e89f;
      span {
        background: #f1c40f;
      }
    `};

  ${({ type }) =>
    type === "2" &&
    css`
      background: #fde4dd;
      border-color: #f9b29f;
      span {
        background: #f13c0f;
      }
    `};

  ${({ type }) =>
    type === "3" &&
    css`
      background: #dffddd;
      border-color: #a5f99f;
      span {
        background: #22f10f;
      }
    `};
  ${({ type }) =>
    type === "4" &&
    css`
      background: #fcefd4;
      border-color: #f9dc9f;
      span {
        background: #f1c40f;
      }
    `};

  ${({ open }) =>
    open &&
    css`
      height: 300px;
    `};
`;

export const ShowButton = styled.button`
  height: 32px;
  width: 32px;
  background: url("assets/icons/arrow.svg") no-repeat center;
  border: none;
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 24px;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `};
`;

export const QuakeDetail = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
export const QuakeSummary = styled.div`
  display: flex;
  width: 100%;
`;
export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  overflow: hidden;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: red;
  margin-top: 20px;
  background: #fde4dd;
  font-size: 18px;
  padding: 20px;
`;

export const Location = styled.strong`
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const DateWrap = styled.ul`
  display: flex;
  margin: 7px 0 0 0;
  flex-direction: row;
  width: 100%;
  list-style: none;
  justify-content: start;
  padding: 0;
  font-weight: 600;
  font-size: 13px;

  li {
    margin-right: 10px;
    position: relative;
    padding-left: 25px;
    text-transform: capitalize;
    &:nth-child(1) {
      min-width: 80px;
      &:before {
        content: "";
        width: 20px;
        height: 22px;
        background: url("/assets/icons/derinlik.png") no-repeat left center;
        background-size: 75%;
        position: absolute;
        left: 0;
        top: -3px;
      }
    }

    &:nth-child(2) {
      &:before {
        content: "";
        width: 18px;
        height: 22px;
        background: url("/assets/icons/date.svg") no-repeat left center;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: -3px;
      }
    }
  }
`;

export const NoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: red;
  margin-top: 20px;
  background: #fde4dd;
  font-size: 18px;
  padding: 20px;
`;
