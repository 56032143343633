import styled, { css } from "styled-components";

export const ChartWrap = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  max-width: 100%;
  padding: 0 4px;
  margin-bottom: 60px;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      font-size: 11px;
      line-height: 13px;
    `};
  ul {
    width: 100%;
    padding: 0 15px 0 15px;
    ${({ mobileCheck }) =>
      !mobileCheck &&
      css`
        padding: 0;
      `};
  }
`;

export const ListItem = styled.li`
  list-style-type: none;
  padding: 10px 10px 10px 35px;
  border: 1px solid gray;
  margin-bottom: 10px;
  background: url("/assets/icons/location.svg") no-repeat 10px center;
  background-size: 20px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: lightgray;
  }
  &:after {
    background: url("/assets/icons/arrow.svg");
    width: 15px;
    height: 8px;
    position: absolute;
    right: 10px;
    top: 15px;
    transform: rotate(-90deg);
    content: "";
  }
`;
export const PageTitle = styled.h5`
  font-size: 16px;
  margin: 10px 0;
  padding: 0 15px 0 15px;
  line-height: 20px;
  ${({ mobileCheck }) =>
    !mobileCheck &&
    css`
      padding: 0;
      br {
        display: none;
      }
    `};
`;
