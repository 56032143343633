import styled, { css } from "styled-components";

export const BottomMenuWrap = styled.div`
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  padding-top: 8px;
  z-index: 1000;
  box-shadow: inset 0 2px 3px -3px rgba(50, 50, 50, 0.75);
  ${({ mobileCheck }) =>
    !mobileCheck &&
    css`
      width: 770px;
      left: 0;
      right: 0;
      margin: 0 auto;
    `};
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    li {
      min-width: 20%;
      a {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #000;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      i {
        display: flex;
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: 100%;
        align-items: center;
        justify-content: center;
      }
      span {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const HomeIcon = styled.i`
  background: url("/assets/icons/home.svg");
`;
export const RefreshIcon = styled.i`
  background: url("/assets/icons/refresh.svg");
`;
export const FilterIcon = styled.i`
  background: url("/assets/icons/search.svg");
`;

export const OrderIcon = styled.i`
  background: url("/assets/icons/order.svg");
`;
export const MapIcon = styled.i`
  background: url("/assets/icons/map.svg");
`;
