import React from "react";
import StatisticsList from "../../components/Chart";

const Statistics = ({ data }) => {
  return (
    <>
      <StatisticsList data={data} />
    </>
  );
};

export default Statistics;
