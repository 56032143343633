import List from "../../components/List";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "../../components/Popup";
import { filterFunc } from "../../utils/groups";
import { Input, Label, Select, SelectWrapper } from "./style";
import { decodeTurkishCharacters } from "../../utils/decodeTurkishCharacters";

const QuakeList = ({ data, getQuake }) => {
  const location = useLocation();
  const paramsString = location?.search;
  const params = paramsString && new URLSearchParams(paramsString);
  const [quake, setQuake] = useState([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [filter, setFilter] = useState({
    city: "all",
    sort: "default",
    search: null,
  });

  const changeFilter = useCallback(e => {
    const { name, value } = e.target;
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  const togglePopup = () => {
    setIsOpenPopup(!isOpenPopup);
    params?.delete("searchPopup");
    window?.history?.replaceState({}, "", `${location?.pathname}?${params}`);
  };

  const handleFilter = () => {
    let filteredData = [...data];

    if (filter?.city !== "all") {
      filteredData = filteredData?.filter(item => {
        return item?.city === filter?.city;
      });
    }

    if (filter?.search && filter?.search?.length > 0) {
      filteredData = filteredData?.filter(item => {
        return item?.title?.includes(decodeTurkishCharacters(filter?.search));
      });
    }

    if (filter?.sort !== "default") {
      if (filter?.sort === "ye") {
        filteredData = filteredData
          ?.slice()
          .sort((a, b) => new Date(b?.date) - new Date(a?.date));
      } else if (filter?.sort === "ey") {
        filteredData = filteredData
          ?.slice()
          .sort((a, b) => new Date(a?.date) - new Date(b?.date));
      } else if (filter?.sort === "desc") {
        filteredData = filteredData?.slice().sort((a, b) => b?.ml - a?.ml);
      } else if (filter?.sort === "asc") {
        filteredData = filteredData?.slice().sort((a, b) => a?.ml - b?.ml);
      }
    }

    setIsOpenPopup(!isOpenPopup);
    setQuake(filteredData);

    setFilter({
      city: "all",
      sort: "default",
      search: null,
    });
  };

  useEffect(() => {
    setQuake(data);
  }, [data]);

  useEffect(() => {
    if (params && params.get("searchPopup")) {
      togglePopup();
    }
  }, [location]);

  return (
    <>
      <List data={quake} getQuake={getQuake} />
      {isOpenPopup && (
        <Popup
          open={isOpenPopup}
          toggleItem={togglePopup}
          handleFilter={handleFilter}
        >
          <Label htmlFor="search">Arama</Label>
          <Input
            placeholder="Kelime ile filtrele"
            type="text"
            id="search"
            name="search"
            onChange={e => changeFilter(e)}
          />

          <Label htmlFor="city">İle Göre Filtrele</Label>
          <SelectWrapper>
            <Select name="city" id="city" onChange={e => changeFilter(e)}>
              <option value="all">Tüm İller</option>
              {Object?.keys(filterFunc(data))
                .sort()
                ?.map((key, index) => (
                  <option key={index} value={key}>
                    {key}
                  </option>
                ))}
            </Select>
          </SelectWrapper>

          <Label htmlFor="sort">Sıralama</Label>
          <SelectWrapper>
            <Select name="sort" id="sort" onChange={e => changeFilter(e)}>
              <option value="">Sırala</option>
              <option value="desc">Büyükten Küçüğe (Deprem Büyüklüğü)</option>
              <option value="asc">Küçükten Büyüğe (Deprem Büyüklüğü)</option>
              <option value="ye">Yeniden Eskiye (Tarih)</option>
              <option value="ey">Eskiden Yeniye (Tarih)</option>
            </Select>
          </SelectWrapper>
        </Popup>
      )}
    </>
  );
};

export default QuakeList;
