import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid gray;
`;
export const SelectWrapper = styled.div`
  width: 100%;
  height: 40px;
  overflow: hidden;
  padding-left: 10px;
  background: url("assets/icons/select-arrow.svg") 95% center no-repeat
    rgb(255, 255, 255);
  background-size: 13px;
  margin: 10px 0 10px;
  border: 1px solid rgb(180, 180, 187);
  border-radius: 4px;
  position: relative;
`;
export const Select = styled.select`
  padding: 11px 8px;
  width: 93%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  appearance: none;
  font-size: 17px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #000;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;
