import { MapContainer, Marker, TileLayer } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { getCenter } from "geolib";
import Leaflet from "leaflet";

import "leaflet/dist/leaflet.css";

import QUAKE from "../../services/data";

var mapIcon = Leaflet.icon({
  iconUrl: "../assets/icons/pin.png",
  iconSize: [25, 38], // size of the icon
});

const AllQuakesMap = () => {
  const [centerCoordinates, setCenterCoordinates] = useState(null);
  const [quake, setQuake] = useState();
  const getQuake = async () => {
    try {
      const res = await QUAKE.GET_ALL_QUAKE();
      setQuake(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuake();
  }, []);
  useEffect(() => {
    const centerCoordinates = getCenter(
      quake?.map(item => ({
        latitude: item.lat,
        longitude: item.lng,
      }))
    );
    setCenterCoordinates(centerCoordinates);
  }, [quake]);

  useEffect(() => {
    getQuake();
  }, []);

  return (
    <>
      {centerCoordinates && (
        <MapContainer
          center={[centerCoordinates?.latitude, centerCoordinates?.longitude]}
          zoom={6}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {quake?.map((item, index) => (
            <Marker
              position={[item.lat, item.lng]}
              key={index}
              icon={mapIcon}
            ></Marker>
          ))}
        </MapContainer>
      )}
    </>
  );
};

export default AllQuakesMap;
