import { v4 as uuidv4 } from "uuid";

import API from "./config";

const QUAKE = {
  GET_ALL_QUAKE: async () => {
    // deprem/live.php?limit=100
    const result = await API.get(`today.json?v=${uuidv4()}`);
    return result?.data;
  },

  GET_QUAKE_BY_COUNTRY: async id => {
    const result = await API.get(`filter?city=${id}`);
    return result?.data;
  },
};
export default QUAKE;
