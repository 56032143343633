import isMobile from "ismobilejs";

import {
  HeaderWrapper,
  TextWrap,
  Logo,
  LogoText,
  LogoTextDetail,
  StoreLogoWrap,
} from "./style";

const mobileCheck =
  isMobile(window.navigator).phone || isMobile(window.navigator).tablet;

const Header = ({ fixed }) => {
  return (
    <HeaderWrapper mobileCheck={mobileCheck} fixed={fixed}>
      <Logo mobileCheck={mobileCheck}>
        <img loading="lazy" src="/assets/images/logo.png" alt="Logo" />
      </Logo>
      <TextWrap mobileCheck={mobileCheck}>
        <LogoText mobileCheck={mobileCheck}>KANDİLLİ RASATHANESİ</LogoText>
        <LogoTextDetail mobileCheck={mobileCheck}>
          TÜRKİYE VE YAKIN ÇEVRESİNDEKİ <br />
          SON 500 DEPREM
        </LogoTextDetail>
      </TextWrap>

      {!mobileCheck && (
        <StoreLogoWrap>
          <a
            href="https://play.google.com/store/apps/details?id=edu.boun.rasathane&gl=TR"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/assets/images/google-play.png" alt="Plat Store" />
          </a>
          <a
            href="https://apps.apple.com/ng/app/rasathane/id1591613322?platform=iphone"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/assets/images/appstore.png" alt="App Store" />
          </a>
        </StoreLogoWrap>
      )}
    </HeaderWrapper>
  );
};
export default Header;
