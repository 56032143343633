import React from "react";
import AllQuakesMap from "../../components/Map";

const AllQuakes = () => {
  return (
    <div className="allQuakesWrapper">
      <AllQuakesMap />
    </div>
  );
};

export default AllQuakes;
