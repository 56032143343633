import styled from "styled-components";

export const PopupWrapper = styled.div``;

export const PopupCloseButton = styled.button`
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 40px;
  background: #d9534f;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  position: absolute;
  left: 0;
  bottom: 53px;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1000;
`;
export const EarthQuakeDetail = styled.ul`
  color: #000;
  position: relative;
  z-index: 1000;
  width: 100%;
  margin-top: 15px;
  padding: 0 10px 50px 10px;
`;

export const EarthQuakeDetailItem = styled.li`
  font-size: 12px;
  margin-bottom: 5px;
`;
export const EarthQuakeDetailTitle = styled.h3`
  font-size: 13px;
  margin: 0 10px 0 0;
`;
