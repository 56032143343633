import { useEffect, useState } from "react";
import { filterFunc } from "../../utils/groups";
import { sortByValueAndReturnObject } from "../../utils/sortByValueAndReturnObject";
import { ChartWrap, ListItem, PageTitle } from "./style";
import isMobile from "ismobilejs";
import { Link } from "react-router-dom";

const mobileCheck =
  isMobile(window.navigator).phone || isMobile(window.navigator).tablet;

const StatisticsList = ({ data }) => {
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    setFilterData(sortByValueAndReturnObject(filterFunc(data)));
  }, [data]);

  return (
    <ChartWrap>
      <PageTitle mobileCheck={mobileCheck}>
        SON 500 DEPREMDEN <br /> EN ÇOK ETKİLENEN İL SIRALAMASI
      </PageTitle>
      <ul>
        {Object?.keys(filterData)?.map((key, index) => {
          return (
            <Link key={index} to={`/statistics/${key}`}>
              <ListItem>
                {key} ({filterFunc(data)[key]} Deprem)
              </ListItem>
            </Link>
          );
        })}
      </ul>
    </ChartWrap>
  );
};

export default StatisticsList;
