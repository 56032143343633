import PullToRefresh from "react-simple-pull-to-refresh";
import {useState} from "react";
import dayjs from "dayjs";
import isMobile from "ismobilejs";

import {colorControl} from "../../utils/colorControl";
import DetailPopup from "../DetailPopup";
import "dayjs/locale/tr";
import {
    ListWrapper,
    ListItem,
    QuakeDetail,
    DetailWrapper,
    DateWrap,
    QuakeSummary,
    PullToRefreshWrap,
    Location,
    ShowButton, NoContent,
} from "./style";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
const mobileCheck =
    isMobile(window.navigator).phone || isMobile(window.navigator).tablet;

// type 1 = sarı
// type 2 = kırmızı
// type 3 = yeşil
// type 4 = turuncu

const List = ({data, getQuake}) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleItem = (item, index) => {
        setSelectedIndex({item, index});
        setIsOpen(!isOpen);
        document.body.classList.toggle("noScroll", !isOpen);
    };

    return (
        <>
            {isOpen && <DetailPopup open={isOpen} toggleItem={toggleItem} item={selectedIndex}/>}
            <PullToRefreshWrap>
                <PullToRefresh onRefresh={getQuake} loading={false}>
                    <ListWrapper mobileCheck={mobileCheck}>
                        {data?.map((item, index) => {
                            const {title, ml, date, depth} = item;
                            return (
                                ml && (
                                    <ListItem
                                        type={colorControl(ml)}
                                        key={index}
                                        mobileCheck={mobileCheck}
                                        onClick={() => toggleItem(item, index)}
                                    >
                                        <QuakeSummary>
                                            <QuakeDetail>
                                                <span>{ml}</span>
                                                <DetailWrapper>
                                                    <Location>{title}</Location>
                                                    <DateWrap>
                                                        <li>{depth} Km.</li>
                                                        <li>
                                                            {dayjs().locale("tr").from(dayjs(date?.replace(/\./g, "-")), true)} önce
                                                        </li>
                                                    </DateWrap>
                                                    <ShowButton
                                                        open={
                                                            selectedIndex !== null &&
                                                            selectedIndex?.index === index
                                                        }
                                                    />
                                                </DetailWrapper>
                                            </QuakeDetail>
                                        </QuakeSummary>
                                    </ListItem>
                                )
                            );
                        })}
                    </ListWrapper>
                </PullToRefresh>
            </PullToRefreshWrap>
        </>
    );
};
export default List;
