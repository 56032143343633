import {
  PopupWrapper,
  PopupInner,
  PopupCloseButton,
  PopupFilterButton,
} from "./styles";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

const Popup = ({ toggleItem, open, handleFilter, children }) => {
  return (
    <BottomSheet
      open={open}
      defaultSnap={({ snapPoints, lastSnap }) =>
        lastSnap ?? Math.min(...snapPoints)
      }
      snapPoints={({ maxHeight }) => [maxHeight * 0.8]}
      onDismiss={() => toggleItem()}
    >
      <PopupWrapper>
        {children}
        <PopupFilterButton onClick={handleFilter}>FİLTRELE</PopupFilterButton>
        <PopupCloseButton onClick={() => toggleItem()}>KAPAT</PopupCloseButton>
      </PopupWrapper>
    </BottomSheet>
  );
};

export default Popup;
