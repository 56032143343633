import {Suspense, useEffect, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import AllQuakes from "./pages/allQuakes/AllQuakes";
import Header from "./components/Header";

import {Loading, Message} from "./components/List/style";

import QUAKE from "./services/data";
import Statistics from "./pages/statistics";

import StatisticsDetial from "./pages/statisticsDetial";
import BottomMenu from "./components/BottomMenu";
import QuakeList from "./pages/quakeList";

function App() {

    const [quake, setQuake] = useState();
    const [message, setMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [headerClass, setHeaderClass] = useState(false);

    const getQuake = async () => {
        setLoading(true);
        try {
            const res = await QUAKE.GET_ALL_QUAKE();
            setQuake(res);
            setLoading(false);
        } catch (error) {
            setMessage(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        getQuake();
    }, []);

    useEffect(() => {
        const handleScroll = () => setHeaderClass(window.pageYOffset > 95);

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="wrapper">
            <Suspense fallback={<Loading/>}>
                <BrowserRouter>
                    <Header getQuake={getQuake} fixed={headerClass}/>
                    {loading && <Loading/>}
                    <Routes>
                        <Route path="/" element={<QuakeList data={quake} getQuake={getQuake}/>}/>
                        <Route path="/allquakes" element={<AllQuakes/>}/>
                        <Route path="/statistics" element={<Statistics data={quake}/>}/>
                        <Route path="/statistics/:city" element={<StatisticsDetial/>}/>
                        <Route path="/search" element={<AllQuakes/>}/>
                        <Route path="/filter" element={<AllQuakes/>}/>
                        <Route path="*" element={<div>404 Not Found</div>}/>
                    </Routes>
                    {message && <Message>Bir hata oluştu</Message>}
                    <BottomMenu getQuake={getQuake}/>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
