export const filterFunc = (x) => {
    let filtered = [];
    let countItem;
    x?.forEach(item => {
        if(item.city !== null)      {
            return filtered.push(item?.city)
        }
    })
    countItem = filtered.reduce((acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1
    }), {});
    return countItem
}
