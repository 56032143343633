import { useEffect } from "react";
import SingleQuakes from "../Map/singleQuake";
import {
  PopupWrapper,
  EarthQuakeDetail,
  EarthQuakeDetailItem,
  PopupCloseButton,
  EarthQuakeDetailTitle,
} from "./styles";
import dayjs from "dayjs";
import { BottomSheet } from "react-spring-bottom-sheet";

const DetailPopup = ({ open, item, toggleItem }) => {
  const {
    lat,
    lng,
    title,
    ml,
    depth,
    date_day,
    date_hour,
    date,
    type,
    md,
    mw,
  } = item?.item;

  useEffect(() => {
    const closePopup = event => {
      if (event.target.id === "popup") {
        toggleItem();
      }
    };
    document.addEventListener("click", closePopup);
    return () => {
      document.removeEventListener("click", closePopup);
    };
  }, []);

  return (
    <BottomSheet
      open={open}
      onDismiss={() => toggleItem()}
      defaultSnap={({ snapPoints, lastSnap }) =>
        lastSnap ?? Math.min(...snapPoints)
      }
      snapPoints={({ maxHeight }) => [maxHeight, maxHeight * 0.8]}
    >
      <PopupWrapper id="popup">
        {<SingleQuakes lat={lat} lng={lng} />}
        <EarthQuakeDetail>
          <EarthQuakeDetailItem>
            <EarthQuakeDetailTitle>{title}</EarthQuakeDetailTitle>
          </EarthQuakeDetailItem>
          <EarthQuakeDetailItem>
            <b>Depremin Şiddeti</b> : {ml}
          </EarthQuakeDetailItem>
          <EarthQuakeDetailItem>
            <b>Derinlik</b> : {depth} KM | <b>MD</b> : {md} | <b>Mw</b> : {mw}
          </EarthQuakeDetailItem>
          <EarthQuakeDetailItem>
            <b>Çözüm Niteliği</b> : {type}
          </EarthQuakeDetailItem>
          <EarthQuakeDetailItem>
            <b>Tarih/Saat</b> :{" "}
            {dayjs(date_day?.replace(/\./g, "-") + date_hour).format(
              "DD.MM.YYYY HH:mm:ss"
            )}
          </EarthQuakeDetailItem>
          <EarthQuakeDetailItem>
            <b>Geçen Süre</b> :{" "}
            {dayjs()
              .locale("tr")
              .from(dayjs(date?.replace(/\./g, "-")), true)}{" "}
            önce
          </EarthQuakeDetailItem>
        </EarthQuakeDetail>
        <PopupCloseButton onClick={() => toggleItem()}>KAPAT</PopupCloseButton>
      </PopupWrapper>
    </BottomSheet>
  );
};

export default DetailPopup;
