import styled from "styled-components";

export const PopupWrapper = styled.div`
  padding: 8px 8px 50px 8px;
`;

export const PopupCloseButton = styled.button`
  width: calc(100% - 10px);
  margin-left: 5px;
  height: 40px;
  background: #d9534f;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  position: absolute;
  left: 0;
  bottom: 53px;
  border-radius: 4px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1000;
`;

export const PopupFilterButton = styled.button`
  height: 40px;
  background: #337ab7;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: none;
  position: absolute;
  left: 0;
  bottom: 110px;
  width: calc(100% - 10px);
  border-radius: 4px;
  margin-left: 5px;
  font-weight: 700;
  cursor: pointer;
`;

export const EarthQuakeDetail = styled.ul`
  color: #000;
  position: relative;
  z-index: 1000000;
  width: 100%;
  margin-top: 15px;
`;

export const EarthQuakeDetailItem = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
`;
