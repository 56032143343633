import styled, { css } from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  height: 95px;
  border-bottom: 1px solid #e4e4e4;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      align-items: center;
    `};
  ${({ fixed, mobileCheck }) =>
    fixed &&
    mobileCheck &&
    css`
      position: fixed;
      width: 100%;
      z-index: 995;
      background: #fff;
      height: 75px;
      top: 0;
    `};

  ${({ fixed, mobileCheck }) =>
    fixed &&
    !mobileCheck &&
    css`
      position: fixed;
      z-index: 995;
      background: #fff;
      width: 770px;
      top: 0;
    `};
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  align-items: center;
  img {
    display: flex;
    width: 75px;
    height: 75px;
    justify-content: center;

    ${({ mobileCheck }) =>
      mobileCheck &&
      css`
        width: 55px;
        height: 55px;
      `};
  }
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      margin: 0 0 0 10px;
    `};
`;
export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  justify-content: center;
  height: 95px;
  padding-left: 15px;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      min-width: calc(100% - 150px);
    `};
`;

export const LogoText = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin: 0 0 5px 0;
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      font-size: 15px;
      margin: 0;
    `};
`;
export const LogoTextDetail = styled.strong`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  br {
    display: none;
  }
  ${({ mobileCheck }) =>
    mobileCheck &&
    css`
      font-size: 11px;
      line-height: 13px;
      br {
        display: inline-block;
      }
    `};
`;

export const StoreLogoWrap = styled.div`
  color: red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  a {
    width: 130px;
    margin-left: 2%;
  }
  img {
    width: 100%;
  }
`;

export const MobileButton = styled.button`
  border: none;
  width: 70px;
  height: 28px;
  background: url("assets/icons/refresh.svg") 5px center #000000 no-repeat;
  background-size: 18px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
  text-align: left;
  text-indent: 25px;
  margin-right: 10px;
  line-height: 25px;
  position: relative;
  z-index: 1001;
  align-items: center;
  padding: 0;
`;
