import { MapContainer, Marker, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";

const mapIcon = Leaflet.icon({
  iconUrl: "../assets/icons/pin.png",
  iconSize: [25, 38], // size of the icon
});

const SingleQuakes = ({ lat, lng }) => {
  return (
    <>
      <MapContainer center={[lat, lng]} zoom={11} scrollWheelZoom={true}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[lat, lng]} icon={mapIcon} />
      </MapContainer>
    </>
  );
};

export default SingleQuakes;
