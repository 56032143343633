import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import QUAKE from "../../services/data";
import List from "../../components/List";
import {Loading} from "../../components/List/style";

const StatisticsDetial = () => {
    const {city} = useParams();
    const [filter, setFilter] = useState();
    const [message, setMessage] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getFilterData = async () => {
        setLoading(true);
        try {
            const res = await QUAKE.GET_QUAKE_BY_COUNTRY(city);
            setFilterData(res);
            setLoading(false);
        } catch (error) {
            setMessage(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (city) {
            getFilterData();
        }
    }, [city])

    return (
        <div>
            {filterData && <List data={filterData} getQuake={getFilterData}/>}
            {loading && <Loading/>}
        </div>
    )
}
export default StatisticsDetial;