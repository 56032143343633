import {
  BottomMenuWrap,
  HomeIcon,
  RefreshIcon,
  FilterIcon,
  OrderIcon,
  MapIcon,
} from "./styles";
import { Link } from "react-router-dom";
import isMobile from "ismobilejs";
const mobileCheck =
  isMobile(window.navigator).phone || isMobile(window.navigator).tablet;
const BottomMenu = ({getQuake}) => {
  return (
    <BottomMenuWrap mobileCheck={mobileCheck}>
      <ul>
        <li>
          <Link to="/">
            <HomeIcon onClick={() => getQuake()}/>
            <span>Anasayfa</span>
          </Link>
        </li>
        <li>
          <Link to="/">
            <RefreshIcon onClick={() => getQuake()}/>
            <span>Yenile</span>
          </Link>
        </li>
        <li>
          <Link to="/?city=all&sort=default&searchPopup=true">
            <FilterIcon />
            <span>Filtrele</span>
          </Link>
        </li>
        <li>
          <Link to="/statistics">
            <OrderIcon />
            <span>İstatistik</span>
          </Link>
        </li>
        <li>
          <Link to="/allQuakes">
            <MapIcon />
            <span>Harita</span>
          </Link>
        </li>
      </ul>
    </BottomMenuWrap>
  );
};

export default BottomMenu;
