import axios from "axios";

//https://api.orhanaydogdu.com.tr/
const PROD_URL = "https://deprem.truncgil.com/";

const API = axios.create({
  baseURL: PROD_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default API;
