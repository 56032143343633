export const colorControl = number => {
  number = parseInt(number, 10);
  if (number >= 7) {
    return "2";
  } else if (number >= 5) {
    return "4";
  } else if (number >= 3) {
    return "1";
  } else {
    return "3";
  }
};
